<template>
  <svg
    id="34ff4b57-28cc-40bf-b74a-be6e59856ead"
    data-name="Layer 1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 37 37"><path
      d="M7.37,0H29.62A7.38,7.38,0,0,1,37,7.38V29.63A7.37,7.37,0,0,1,29.62,37H7.37A7.37,7.37,0,0,1,0,29.63V7.37A7.37,7.37,0,0,1,7.37,0Z"
      class="icon-wrapper__template"
      :fill="color" /><path
        d="M26.31,7.9a10.2,10.2,0,0,0-7.15,3.38.75.75,0,0,0-.21.52v17a.73.73,0,0,0
        ,.73.74h6.65a.73.73,0,0,0,.73-.74V8.64A.73.73,0,0,0,26.31,7.9Zm-.58,19.83a.38.38,0,0
        ,1-.38.38h-4.7a.38.38,0,0,1-.38-.38v-4.5a.38.38,0,0,1,.38-.38h4.7a.38.38,0,0,1
        ,.38.38Zm0-6.43a.38.38,0,0,1-.38.38h-4.7a.38.38,0,0,1-.38-.38V20a.38.38,0,0,1,
        .38-.38h4.7a.38.38,0,0,1,.38.38Zm0-2.92a.38.38,0,0,1-.38.38h-4.7a.38.38,0,0
        ,1-.38-.38V17.06a.38.38,0,0,1,.38-.38h4.7a.38.38,0,0,1,.38.38Zm0-3a.39.39,0,0
        ,1-.38.39h-4.7a.39.39,0,0,1-.38-.39V14.06a.38.38,0,0,1,.38-.38h4.7a.38.38,0,0,1,.38.38Z"
        transform="translate(0 -0.03)"
        fill="#fff" /><path
          d="M16.16,8.63a12.1,12.1,0,0,1-6.77,2.85.52.52,0,0,0-.49.52V29a.52.52,0,0,0,
          .52.53h7.07A.53.53,0,0,0,17,29V9A.53.53,0,0,0,16.16,8.63Zm-.47,19.1a.39.39,
          0,0,1-.39.38h-4.7a.38.38,0,0,1-.38-.38v-4.5a.38.38,0,0,1,.38-.38h4.7a.39.39,
          0,0,1,.39.38Zm0-6.43a.39.39,0,0,1-.39.38h-4.7a.38.38,0,0,1-.38-.38V20a.38.38,
          0,0,1,.38-.38h4.7a.39.39,0,0,1,.39.38Zm0-2.92a.39.39,0,0,1-.39.38h-4.7a.38.38,
          0,0,1-.38-.38V17.06a.38.38,0,0,1,.38-.38h4.7a.39.39,0,0,1,.39.38Zm0-3a.39.39,
          0,0,1-.39.39h-4.7a.39.39,0,0,1-.38-.39V14.06a.38.38,0,0,1,.38-.38h4.7a.39.39,0,0,1,.39.38Z"
          transform="translate(0 -0.03)"
          fill="#fff" /></svg>
</template>
<script>
import ProjectIconTemplate from '@/mixins/ProjectIconTemplate';
export default {
  mixins: [ProjectIconTemplate],
};
</script>